
	frappe.templates['navbar'] = `<div class="sticky-top">
	<header class="navbar navbar-expand" role="navigation">
		<div class="container">
			<a class="navbar-brand navbar-home" href="/app">
				<img
					class="app-logo"
					src="{{ frappe.boot.app_logo_url }}"
					alt="{{ __("App Logo") }}"
				>
			</a>
			<style>
	.dropdown-menu {
	position: relative;
	overflow: unset;
	}
	.dropdown-menu .dropdown-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
	}
	.dropdown-menu .dropdown-submenu-left {
	right: 100%;
	left: auto;
	}
	.dropdown-menu > div:hover > .dropdown-submenu {
	display: block;
	}
	.dropdown-menu > div:hover {
	background: var(--gray-100);
	}
	.nav-top-menu{
		padding: 10px;
		padding-right: 8px;
		padding-left: 8px;
		border-radius: 4px;
	}
	.nav-top-menu:hover{
		background: var(--gray-100);
	}

	.align-right{
	float: right;
	}
	.dropdown-item{border:#ffffff 1px solid;}

	.item-menu-with-sub{border-left:#343a40 5px solid;}
	.item-tool{border-left:#6c757d 5px solid;color:#6c757d;}
	/*.item-tool:hover{border-left:#343a40 5px solid;background:#6c757d;color:white;}*/
	.item-tool:hover{border:#6c757d 1px solid; border-left:#6c757d 5px solid;}
	.item-report{border-left:#17a2b8 5px solid;color:#17a2b8;}
	/*.item-report:hover{border-left:#343a40 5px solid;background:#17a2b8;color:white;}
	*/
	.item-report:hover{border:#17a2b8 1px solid; border-left:#17a2b8 5px solid;}
	.item-dashboard{border-left:#28a745 5px solid;color:#28a745;}
	/*.item-dashboard:hover{border-left:#343a40 5px solid;background:#28a745;color:white;}
	*/
	.item-dashboard:hover{border:#28a745 1px solid; border-left:#28a745 5px solid;}
	.item-doctype{border-left:#ffc107 5px solid;}
	/*.item-doctype:hover{border-left:#343a40 5px solid;background:#ffc107;}
	*/
	.item-doctype:hover{border:#ffc107 1px solid; border-left:#ffc107 5px solid;}

	</style>
	<script>
	function localstorage_mail_campaign_all_adherents(){
		localStorage.setItem("create-new-mail-campaign", "all-adherents");
	}
	function localstorage_sms_campaign_all_adherents(){
		localStorage.setItem("create-new-sms-campaign", "all-adherents");
	}
	</script>
	<ul class="nav navbar-nav" id="navbar-menu">
		<!-- ADHERENTS -->
	<li class="nav-item nav-top-menu dropdown dropdown-help dropdown-mobile d-none d-lg-block">
	<a class="nav-link" data-toggle="dropdown" href="#">Adh&eacute;rents<span><svg class="icon icon-xs"><use href="#icon-small-down"></use></svg></span></a>
      	<div class="dropdown-menu" style="overflow: unset;">
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/producer_list">Soci&eacute;t&eacute;s adh&eacute;rentes <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/producer_list">Liste detaill&eacute;e</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_adherent_company">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_producer">Producteurs <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_producer">Liste simplifi&eacute;e</a></li>
				<li><a class="dropdown-item item-report" href="/app/query-report/Coordonn%C3%A9es%20des%20adh%C3%A9rents">Coordonn&eacute;es des adh&eacute;rents</a>
				<li><a class="dropdown-item item-report" href="/app/query-report/Nombre%20de%20producteurs%20par%20ann%C3%A9e">Nombre de producteurs par ann&eacute;e</a>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_registration">Adh&eacute;sions <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_registration">Liste simplifi&eacute;e</a></li>
				<li><a class="dropdown-item item-doctype" href="/app/op_registration?docstatus=0">Adh&eacute;sions à valider</a>
				</li>
				<li><a class="dropdown-item item-report" href="/app/query-report/nouveaux_adherents">Liste des adh&eacute;sions par mois</a>
				</li>
			</ul>
		</div>
        	<div class="dropdown-divider"></div>
        	<a class="dropdown-item item-report" href="/app/producer_list">Liste detaill&eacute;e des soci&eacute;t&eacute;s</a>
        	<a class="dropdown-item item-tool" href="/app/advanced_search">Recherche avanc&eacute;e</a>
		<a class="dropdown-item item-tool" href="/app/advanced_export">Export avanc&eacute;</a>
        	<a class="dropdown-item item-dashboard" href="/app/adherents_op">Adh&eacute;rents OP</a>
      	</div>
	</li>

	<!-- PRODUCTION-->
	<li class="nav-item  nav-top-menu dropdown dropdown-help dropdown-mobile d-none d-lg-block">
	<a class="nav-link" data-toggle="dropdown" href="#">Production<span><svg class="icon icon-xs"><use href="#icon-small-down"></use></svg></span></a>
	<div class="dropdown-menu">
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_dairy_file_monthly_production">Fichier de production <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_dairy_file_monthly_production/new">Importer nouveau</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_dairy_file_monthly_production">Liste simplifi&eacute;e</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/monthly_production_file_adherent_active_in_db_missing_from_file">Producteurs manquants</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/monthly_production_file_adherent_present_in_file_missing_from_db">Producteurs inconnus</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Num%20producteur%20sans%20lien">Num. producteur sans lien</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_monthly_production_according_to_dairy">D&eacute;tails de production <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_monthly_production_according_to_dairy">Liste simplifi&eacute;e</a></li>
				<li><a class="dropdown-item item-report" href="/app/query-report/production_statistics_for_campaign">Stats par campagne</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_num_producer_dairy">Num. producteurs <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_num_producer_dairy">Liste simplifi&eacute;e</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Num%20producteur%20sans%20lien">Num. producteur sans lien</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="#">Qualit&eacute;<span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/query-report/controle_apport_total">Contr&ocirc;le apport total</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/query-report/producer_list_above_250_cells_recidivists">P&eacute;nalit&eacute;s cellules</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/query-report/producer_list_above_50_germes_recidivists">P&eacute;nalit&eacute;s germes</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_rate_error">Analyse des primes<span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_cniel_rate">Liste des grilles CNIEL</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_rate_error">Liste des erreurs de taux</a></li>
			</ul>
		</div>
		<div class="dropdown-divider"></div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_vol_transfer">Transferts de volume <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_vol_transfer/new">Cr&eacute;er nouveau</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_vol_transfer">Liste simplifi&eacute;e</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Soci&eacute;t&eacute;s%20avec%20transfert%20de%20volume">Liste soci&eacute;t&eacute;s avec transfert</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Transferts%20de%20volume%20par%20campagne">Transferts par campagne</a></li>
				<li><a class="dropdown-item item-report" href="/app/query-report/Vol%20global%20c%C3%A9d%C3%A9-repris%20par%20l%27OP">Vol. global c&eacute;d&eacute; &sol;repris par l&apos;OP</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="#">Attributions <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-report" href="/app/query-report/Soci&eacute;t&eacute;s%20avec%20attribution%20de%20volume">Liste sociétés avec attribution</a></li>
			</ul>
		</div>
		<div class="dropdown-divider"></div>
		<div style="position: relative;">
			<a class="dropdown-item item-menu-with-sub" href="/app/op_factory">Usines <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_factory/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_factory">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_dairy">Laiteries <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_dairy/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_dairy">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
        	<div class="dropdown-divider"></div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="#">Rapports Fr Agrimer<span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-report" href="/app/query-report/declaration_agrimer_officiel">Declaration officielle</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/declaration_agrimer_campagne">Vue par campagne</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/declaration_agrimer_12_mois_glissants">Vue par 12 mois glissants</a></li>
			</ul>
		</div>
		<a class="dropdown-item item-tool" href="/app/advanced_search">Recherche avanc&eacute;e</a>
		<a class="dropdown-item item-tool" href="/app/advanced_export">Export avanc&eacute;</a>
        	<a class="dropdown-item item-dashboard" href="/app/production_op">Production OP</a>
	</div>
	</li>


	<!-- COMMUNICATION-->
	<li class="nav-item dropdown nav-top-menu dropdown-help dropdown-mobile d-none d-lg-block">
	<a class="nav-link" data-toggle="dropdown" href="#">Communication<span><svg class="icon icon-xs"><use href="#icon-small-down"></use></svg></span></a>
      	<div class="dropdown-menu">
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_campaign_mail">Campagne mail <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_campaign_mail/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_campaign_mail">Liste simplifi&eacute;e</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_campaign_mail/new" onclick="localstorage_mail_campaign_all_adherents()" >Campagne &agrave; tous les adh&eacute;rents</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_campaign_sms">Campagne SMS<span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_campaign_sms/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_campaign_sms">Liste simplifi&eacute;e</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_campaign_sms/new" onclick="localstorage_sms_campaign_all_adherents()" >Campagne &agrave; tous les adh&eacute;rents</a></li>
			</ul>
		</div>
        	<div class="dropdown-divider"></div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_association">Association <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_association/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_association">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_meeting">R&eacute;union <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_meeting/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_meeting">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_workgroup">Groupe de travail <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_workgroup/new">Cr&eacute;er nouveau</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_workgroup">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_survey">Enqu&ecirc;tes<span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_survey/new">Cr&eacute;er nouveau</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_survey">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
	</div>
	</li>

	<!-- PARAMETRES-->
	<li class="nav-item nav-top-menu dropdown dropdown-help dropdown-mobile d-none d-lg-block">
	<a class="nav-link" data-toggle="dropdown" href="#">Param&egrave;tres<span><svg class="icon icon-xs"><use href="#icon-small-down"></use></svg></span></a>
      	<div class="dropdown-menu">
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_legal_status">Statut l&eacute;gal <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_legal_status/new">Cr&eacute;er nouveau</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_legal_status">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/op_producing_campaign">Campagne de production <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-doctype" href="/app/op_producing_campaign/new">Cr&eacute;er nouvelle</a></li>
              			<li><a class="dropdown-item item-doctype" href="/app/op_producing_campaign">Liste simplifi&eacute;e</a></li>
			</ul>
		</div>
        	<div class="dropdown-divider"></div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/erreurs-bdd">Erreurs base de donn&eacute;es <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-report" href="/app/query-report/Erreurs%20date%20adh&eacute;sion">Date d'adh&eacute;sion</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Erreurs%20num%20producteur">Num. producteur</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Erreurs%20EDE">EDE</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Erreurs%20PACAGE">PACAGE</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Erreurs%20SIRET">SIRET</a></li>
			</ul>
		</div>
		<div style="position: relative;">
        		<a class="dropdown-item item-menu-with-sub" href="/app/erreurs-bdd">Doublons base de donn&eacute;es <span class="align-right">&raquo;</span></a>
			<ul class="dropdown-menu dropdown-submenu">
              			<li><a class="dropdown-item item-report" href="/app/query-report/Doublons%20Nom-Pr&eacute;nom">Nom & pr&eacute;nom</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Doublons%20Email">Email</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Doublons%20Nom%20de%20soci&eacute;t&eacute;">Nom de soci&eacute;t&eacute;</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Doublons%20num%20producteur">Num. producteur</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Doublons%20PACAGE">PACAGE</a></li>
              			<li><a class="dropdown-item item-report" href="/app/query-report/Doublons%20SIRET">SIRET</a></li>
			</ul>
		</div>
        	<div class="dropdown-divider"></div>
        	<a class="dropdown-item item-doctype" href="/app/op_settings/op_settings">Param&egrave;tres OP</a>
      	</div>
	</li>
  	</ul>
			<ul class="nav navbar-nav d-none d-sm-flex" id="navbar-breadcrumbs"></ul>
			<div class="collapse navbar-collapse justify-content-end">
				<form class="form-inline fill-width justify-content-end" role="search" onsubmit="return false;">
					{% if (frappe.boot.read_only) { %}
						<span class="indicator-pill yellow no-indicator-dot" title="{%= __("Your site is undergoing maintenance or being updated.") %}">
							{%= __("Read Only Mode") %}
						</span>
					{% } %}
					{% if (frappe.boot.user.impersonated_by) { %}
						<span class="indicator-pill red no-indicator-dot" title="{%= __("You are impersonating as another user.") %}">
							{%= __("Impersonating {0}", [frappe.boot.user.name]) %}
						</span>
					{% } %}
					<div class="input-group search-bar text-muted hidden">
						<input
							id="navbar-search"
							type="text"
							class="form-control"
							placeholder="{%= __('Search or type a command ({0})', [frappe.utils.is_mac() ? '⌘ + G' : 'Ctrl + G']) %}"
							aria-haspopup="true"
						>
						<span class="search-icon">
							<svg class="icon icon-sm"><use href="#icon-search"></use></svg>
						</span>
					</div>
				</form>
				<ul class="navbar-nav">
					<li class="nav-item dropdown dropdown-notifications dropdown-mobile hidden">
						<button
							class="btn-reset nav-link notifications-icon text-muted"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<span class="notifications-seen">
								<span class="sr-only">{{ __("No new notifications") }}</span>
								<svg class="es-icon icon-sm" style="stroke:none;"><use href="#es-line-notifications"></use></svg>
							</span>
							<span class="notifications-unseen">
								<span class="sr-only">{{ __("You have unseen notifications") }}</span>
								<svg class="es-icon icon-sm"><use href="#es-line-notifications-unseen"></use></svg>
							</span>
						</button>
						<div class="dropdown-menu notifications-list dropdown-menu-right" role="menu">
							<div class="notification-list-header">
								<div class="header-items"></div>
								<div class="header-actions"></div>
							</div>
							<div class="notification-list-body">
								<div class="panel-notifications"></div>
								<div class="panel-events"></div>
								<div class="panel-changelog-feed"></div>
							</div>
						</div>
					</li>
					<li class="nav-item dropdown dropdown-message dropdown-mobile hidden">
						<button
							class="btn-reset nav-link notifications-icon text-muted"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="true"
						>
							<span>
								<svg class="es-icon icon-sm"><use href="#es-line-chat-alt"></use></svg>
							</span>
						</button>
					</li>
					<li class="vertical-bar d-none d-sm-block"></li>
					<li class="nav-item dropdown dropdown-help dropdown-mobile d-none d-lg-block">
						<button
							class="btn-reset nav-link"
							data-toggle="dropdown"
							aria-controls="toolbar-help"
							aria-label="{{ __("Help Dropdown") }}"
						>
							<span>
								{{ __("Help") }}
								<svg class="es-icon icon-xs"><use href="#es-line-down"></use></svg>
							</span>
						</button>
						<div class="dropdown-menu dropdown-menu-right" id="toolbar-help" role="menu">
							<div id="help-links"></div>
							<div class="dropdown-divider documentation-links"></div>
							{% for item in navbar_settings.help_dropdown %}
								{% if (!item.hidden) { %}
									{% if (item.route) { %}
										<a class="dropdown-item" href="{{ item.route }}">
											{%= __(item.item_label) %}
										</a>
									{% } else if (item.action) { %}
										<button class="btn-reset dropdown-item" onclick="return {{ item.action }}">
											{%= __(item.item_label) %}
										</button>
									{% } else { %}
										<div class="dropdown-divider"></div>
									{% } %}
								{% } %}
							{% endfor %}
						</div>
					</li>
					<li class="nav-item dropdown dropdown-navbar-user dropdown-mobile">
						<button
							class="btn-reset nav-link"
							data-toggle="dropdown"
							aria-label="{{ __("User Menu") }}"
						>
							{{ avatar }}
						</button>
						<div class="dropdown-menu dropdown-menu-right" id="toolbar-user" role="menu">
							{% for item in navbar_settings.settings_dropdown %}
								{% var condition = item.condition ? eval(item.condition) : true %}
								{% if (condition && !item.hidden) { %}
									{% if (item.route) { %}
										<a class="dropdown-item" href="{{ item.route }}">
											{%= __(item.item_label) %}
										</a>
									{% } else if (item.action) { %}
										<button class="btn-reset dropdown-item" onclick="return {{ item.action }}">
											{%= __(item.item_label) %}
										</button>
									{% } else { %}
										<div class="dropdown-divider"></div>
									{% } %}
								{% } %}
							{% endfor %}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</header>

	{% if !localStorage.getItem("dismissed_announcement_widget") && strip_html(navbar_settings.announcement_widget) != '' %}
	<div class="announcement-widget form-message p-2 m-0" style="position: relative; z-index: -1; border-radius: 0; background-color: var(--bg-blue);">
		<div class="container flex justify-between align-center mx-auto">
			{{ navbar_settings.announcement_widget }}
			<div class="close-message p-0 mr-2" style="position: relative;">
			{{ frappe.utils.icon("close") }}
			</div>
		</div>
	</div>
	{% endif %}

</div>
`;
